

























































































import Vue from "vue";
import QrcodeVue from 'qrcode.vue';

export default Vue.extend({
  name: "Account",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      enablingTotp: false,
      disablingTotp: false,
      totpSecret: null,
      totpUrl: null,
      totp: '',
      password: '',
      testEmailAdress: this.$store.state.authInfo.email,
      testEmailTemplate: 'invoice'
    };
  },
  methods: {
    enableTotp() {
      this.enablingTotp = true;
      this.axios.post('auth/totp/enable')
        .then((res) => {
          this.totpSecret = res.data.secret;
          this.totpUrl = res.data.otpauth;
        });
    },
    disableTotp() {
      this.axios.post('auth/totp/disable', {
        password: this.password,
        token: this.totp,
      })
      .then(() => {
        this.disablingTotp = false;
      })
    },
    verifyTotp() {
      this.enablingTotp = true;
      this.axios.post('auth/totp/verify', {
        token: this.totp,
      })
        .then(() => {
          this.totpSecret = null;
          this.totpUrl = null;
          this.enablingTotp = false;
          this.totp = '';
        });
    },
    sendSignalTest() {
      this.axios.get('signal/test');
    },
    sendEMailTest() {
      this.axios.post('email/send', {
        to: this.testEmailAdress,
        template: this.testEmailTemplate,
        locals: {
          invoiceUrl: 'https://itmr-dev.de',
          paymentUrl: 'https://itmr-dev.de',
        }
      });
    },
  },
});
